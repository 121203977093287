// third-party
import customizationReducer from 'store/customizationReducer';
import { combineReducers } from 'redux';
import { authReducer } from './auth.slice';
import { usersReducer } from './user.slice';
import { companyReducer } from './company.slice';
import { taskReducer } from './task.slice';
import { templateReducer } from './template.slice';
// ==============================|| COMBINE REDUCERS ||============================== //

const combinedReducer = combineReducers({
    authReducer: authReducer,
    taskReducer: taskReducer,
    customization: customizationReducer,
    usersReducer: usersReducer,
    templateReducer: templateReducer,
    companyReducer: companyReducer
});

const rootReducers = (state, action) => {
    if (action?.type === 'USER_LOGOUT') {
        return state;
    }
    return combinedReducer(state, action);
};

export default rootReducers;
