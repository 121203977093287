import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../helper';
import { LoadingStatus } from 'utils/constant';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'company';

const initialState = entityAdapter.getInitialState({
    companyError: null,
    CompanyList: [],
    CompanyDetails: [],
    CompanyLoadingStatus: LoadingStatus.NOT_LOADED
});

export const companyListAction = createAsyncThunk('auth/companyListAction', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/company`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const addCompanyAction = createAsyncThunk('auth/addCompanyAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/add-company`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const getCompanyByIdAction = createAsyncThunk('auth/getCompanyByIdAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/get-company/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const updateCompanyByIdAction = createAsyncThunk('auth/updateCompanyByIdAction', async (params, thunkAPI) => {
    console.log('Parameter we are passing', params);
    try {
        const response = await request({
            url: `api/update-company`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const onDeleteCompanyAction = createAsyncThunk('auth/onDeleteCompanyAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/delete-company/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

const companySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        companyState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // Company List
            .addCase(companyListAction.fulfilled, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADED;
                state.CompanyList = action.payload;
            })
            .addCase(companyListAction.pending, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(companyListAction.rejected, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.FAILED;
                state.companyError = action.payload || action.error.message;
            })

            // Company Add
            .addCase(addCompanyAction.fulfilled, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADED;
            })
            .addCase(addCompanyAction.pending, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCompanyAction.rejected, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.FAILED;
                state.companyError = action.payload || action.error.message;
            })

            // Company get
            .addCase(getCompanyByIdAction.fulfilled, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADED;
                state.CompanyDetails = action.payload;
            })
            .addCase(getCompanyByIdAction.pending, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getCompanyByIdAction.rejected, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.FAILED;
                state.companyError = action.payload || action.error.message;
            })

            // Company Update
            .addCase(updateCompanyByIdAction.fulfilled, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADED;
                state.CompanyDetails = action.payload;
            })
            .addCase(updateCompanyByIdAction.pending, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateCompanyByIdAction.rejected, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.FAILED;
                state.companyError = action.payload || action.error.message;
            })

            // Company Delete
            .addCase(onDeleteCompanyAction.fulfilled, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADED;
                state.CompanyDetails = action.payload;
            })
            .addCase(onDeleteCompanyAction.pending, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(onDeleteCompanyAction.rejected, (state, action) => {
                state.CompanyLoadingStatus = LoadingStatus.FAILED;
                state.companyError = action.payload || action.error.message;
            });
    }
});

export const companyReducer = companySlice.reducer;

export const companyActions = companySlice.actions;
