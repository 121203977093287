// assets
import { IconKey, IconTypography, IconUser } from '@tabler/icons';
import { IconFileExcel, IconFileTypePdf, IconSignature } from '@tabler/icons-react';
// constant
const icons = {
    IconKey,
    IconTypography,
    IconUser,
    IconFileExcel,
    IconFileTypePdf,
    IconSignature
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const management = {
    id: 'management',
    title: 'management',
    type: 'group',
    children: [
        {
            id: 'company',
            title: 'Company Management',
            type: 'item',
            url: '/company/list',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'departmentList',
            title: 'Department Management',
            type: 'item',
            url: '/depart/depart-list',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'userList',
            title: 'User Management',
            type: 'item',
            url: '/users/users-list',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'task',
            title: 'Task Management',
            type: 'item',
            url: '/task/list',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        // {
        //     id: 'template',
        //     title: 'Template Management',
        //     type: 'item',
        //     url: '/template/list',
        //     icon: icons.IconUser,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'pdf',
        //     title: 'Merge Pdf',
        //     type: 'item',
        //     url: '/pdf/generator',
        //     icon: icons.IconFileTypePdf,
        //     breadcrumbs: false
        // },
        {
            id: 'esignature',
            title: 'PDF Signature',
            type: 'item',
            url: '/esignature/list',
            icon: icons.IconSignature,
            breadcrumbs: false
        },
        {
            id: 'mergexcel',
            title: 'Merge Excel',
            type: 'item',
            url: '/mergexcel/list',
            icon: icons.IconFileExcel,
            breadcrumbs: false
        }
    ]
};

export default management;
