import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing

const UserList = Loadable(lazy(() => import('views/management/UsersList')));
const AddUser = Loadable(lazy(() => import('views/management/AddUser')));
const ViewUser = Loadable(lazy(() => import('views/management/ViewUser')));

const Privacy = Loadable(lazy(() => import('views/doc/PrivacyPolicy')));
const Term = Loadable(lazy(() => import('views/doc/TermCondiition')));
const About = Loadable(lazy(() => import('views/doc/About')));
const Generate = Loadable(lazy(() => import('views/pdf/Generate')));
const Signature = Loadable(lazy(() => import('views/pdf/Signature')));
const MergeExcel = Loadable(lazy(() => import('views/pdf/MergeExcel')));

const DepartUsersList = Loadable(lazy(() => import('views/management/DepartUsersList')));
const CompanyList = Loadable(lazy(() => import('views/management/company/Listcompany')));
const FormCompany = Loadable(lazy(() => import('views/management/company/FormCompany')));

const TemplateList = Loadable(lazy(() => import('views/management/template/ListTemplate')));
const FormTemplate = Loadable(lazy(() => import('views/management/template/FormTemplate')));

const ListTask = Loadable(lazy(() => import('views/management/task/ListTask')));
const FormTask = Loadable(lazy(() => import('views/management/task/FormTask')));
const ViewTask = Loadable(lazy(() => import('views/management/task/ViewTask')));

// 18/06/23
const AddDepartment = Loadable(lazy(() => import('views/management/AddDepartment')));
const ViewDepartment = Loadable(lazy(() => import('views/management/ViewDepartment')));

// email management 6/8/23
const EmailList = Loadable(lazy(() => import('views/management/EmailManagement/EmailList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: 'dashboard',
                children: [
                    {
                        path: 'default',
                        element: <DashboardDefault />
                    }
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: 'users-list',
                        element: <UserList />
                    }
                ]
            },
            {
                path: 'adduser',
                children: [
                    {
                        path: 'adduser-list',
                        element: <AddUser />
                    }
                ]
            },
            {
                path: 'adduser',
                children: [
                    {
                        path: 'adduser-list/:id',
                        element: <AddUser />
                    }
                ]
            },
            {
                path: 'viewuser',
                children: [
                    {
                        path: 'viewuser-list/:id',
                        element: <ViewUser />
                    }
                ]
            },
            {
                path: 'depart',
                children: [
                    {
                        path: 'depart-list',
                        element: <DepartUsersList />
                    }
                ]
            },
            {
                path: 'company',
                children: [
                    {
                        path: 'list',
                        element: <CompanyList />
                    }
                ]
            },
            {
                path: 'company',
                children: [
                    {
                        path: 'action/:id',
                        element: <FormCompany />
                    }
                ]
            },
            {
                path: 'company',
                children: [
                    {
                        path: 'action',
                        element: <FormCompany />
                    }
                ]
            },

            {
                path: 'template',
                children: [
                    {
                        path: 'action/:id',
                        element: <FormTemplate />
                    }
                ]
            },
            {
                path: 'template',
                children: [
                    {
                        path: 'action',
                        element: <FormTemplate />
                    }
                ]
            },
            {
                path: 'template',
                children: [
                    {
                        path: 'list',
                        element: <TemplateList />
                    }
                ]
            },
            {
                path: 'task',
                children: [
                    {
                        path: 'list',
                        element: <ListTask />
                    }
                ]
            },
            {
                path: 'task',
                children: [
                    {
                        path: 'action/:id',
                        element: <FormTask />
                    }
                ]
            },
            {
                path: 'task',
                children: [
                    {
                        path: 'action',
                        element: <FormTask />
                    }
                ]
            },
            {
                path: 'task',
                children: [
                    {
                        path: 'view/:id',
                        element: <ViewTask />
                    }
                ]
            },

            {
                path: 'adddepart',
                children: [
                    {
                        path: 'adddepart-list',
                        element: <AddDepartment />
                    }
                ]
            },
            {
                path: 'adddepart',
                children: [
                    {
                        path: 'adddepart-list/:id',
                        element: <AddDepartment />
                    }
                ]
            },
            {
                path: 'viewdepart',
                children: [
                    {
                        path: 'viewdepart-list/:id',
                        element: <ViewDepartment />
                    }
                ]
            },
            {
                path: 'home',
                children: [
                    {
                        path: 'privacy_policy',
                        element: <Privacy />
                    }
                ]
            },
            {
                path: 'home',
                children: [
                    {
                        path: 'term_condition',
                        element: <Term />
                    }
                ]
            },
            {
                path: 'home',
                children: [
                    {
                        path: 'about_us',
                        element: <About />
                    }
                ]
            },
            {
                path: 'pdf',
                children: [
                    {
                        path: 'generator',
                        element: <Generate />
                    }
                ]
            },
            {
                path: 'esignature',
                children: [
                    {
                        path: 'list',
                        element: <Signature />
                    }
                ]
            },
            {
                path: 'mergexcel',
                children: [
                    {
                        path: 'list',
                        element: <MergeExcel />
                    }
                ]
            }
        ]
    }
];

export default MainRoutes;
