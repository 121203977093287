import dashboard from './dashboard';
import management from './management';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, management]
};

export default menuItems;
