import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../helper';
import { LoadingStatus } from 'utils/constant';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'template';

const initialState = entityAdapter.getInitialState({
    templateError: null,
    templateList: [],
    templateDetails: [],
    templateLoadingStatus: LoadingStatus.NOT_LOADED
});

export const templateListAction = createAsyncThunk('auth/templateListAction', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/template`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const addTemplateAction = createAsyncThunk('auth/T', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/add-template`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const getTemplateByIdAction = createAsyncThunk('auth/getTemplateByIdAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/get-template/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const updateTemplateByIdAction = createAsyncThunk('auth/updateTemplateByIdAction', async (params, thunkAPI) => {
    console.log('Parameter we are passing', params);
    try {
        const response = await request({
            url: `api/update-template`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const onDeleteTemplateAction = createAsyncThunk('auth/onDeleteTemplateAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/delete-template/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

const templateSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        templateState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // template List
            .addCase(templateListAction.fulfilled, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADED;
                state.templateList = action.payload;
            })
            .addCase(templateListAction.pending, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(templateListAction.rejected, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.FAILED;
                state.templateError = action.payload || action.error.message;
            })

            // template Add
            .addCase(addTemplateAction.fulfilled, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADED;
            })
            .addCase(addTemplateAction.pending, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addTemplateAction.rejected, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.FAILED;
                state.templateError = action.payload || action.error.message;
            })

            // template get
            .addCase(getTemplateByIdAction.fulfilled, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADED;
                state.templateDetails = action.payload;
            })
            .addCase(getTemplateByIdAction.pending, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getTemplateByIdAction.rejected, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.FAILED;
                state.templateError = action.payload || action.error.message;
            })

            // template Update
            .addCase(updateTemplateByIdAction.fulfilled, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADED;
                state.templateDetails = action.payload;
            })
            .addCase(updateTemplateByIdAction.pending, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateTemplateByIdAction.rejected, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.FAILED;
                state.templateError = action.payload || action.error.message;
            })

            // template Delete
            .addCase(onDeleteTemplateAction.fulfilled, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADED;
                state.templateDetails = action.payload;
            })
            .addCase(onDeleteTemplateAction.pending, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(onDeleteTemplateAction.rejected, (state, action) => {
                state.templateLoadingStatus = LoadingStatus.FAILED;
                state.templateError = action.payload || action.error.message;
            });
    }
});

export const templateReducer = templateSlice.reducer;

export const templateActions = templateSlice.actions;
