import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'utils/constant';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'user';

const initialState = entityAdapter.getInitialState({
    registerError: null,
    RegisterLoadingStatus: LoadingStatus.NOT_LOADED
});

export const allUserAction = createAsyncThunk(`${SLICE_NAME}/allUsersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/user-list',
            method: 'GET'
        });
        return result;
    } catch (error) {
        // console.log('error', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const registerUser = createAsyncThunk(`${SLICE_NAME}/register`, async (params, thunkAPI) => {
    // console.log('>>>>>>>>>>>', params);
    // const {...reqBody } = params;
    try {
        const result = await axiosRequest({
            url: '/register-user',
            method: 'post'
        });
        return result;
    } catch (error) {
        // console.log('error', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

const usersSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        userState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // stay login action
            .addCase(registerUser.fulfilled, (state, action) => {
                state.RegisterLoadingStatus = LoadingStatus.LOADED;
            })
            // login action
            .addCase(registerUser.pending, (state, action) => {
                console.log('hiiddd', state);
                state.RegisterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.RegisterLoadingStatus = LoadingStatus.FAILED;
                state.registerError = action.payload || action.error.message;
            });
    }
});

export const usersReducer = usersSlice.reducer;

export const usersActions = usersSlice.actions;
