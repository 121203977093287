/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../helper';
import ToastMessage from 'utils/ToastMessage';
import { LoadingStatus } from 'utils/constant';
import LocalStorage from 'utils/localStorage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'auth';

const initialState = entityAdapter.getInitialState({
    loginLoadingStatus: LoadingStatus.NOT_LOADED,
    forgotPasswordLoadingStatus: LoadingStatus.NOT_LOADED,
    departmentLoadingStatus: LoadingStatus.NOT_LOADED,
    adddemartmentLoadingStatus: LoadingStatus.NOT_LOADED,
    getdemartmentLoadingStatus: LoadingStatus.NOT_LOADED,
    deletedemartmentLoadingStatus: LoadingStatus.NOT_LOADED,
    updatedemartmentLoadingStatus: LoadingStatus.NOT_LOADED,
    stayLoginLoadingStatus: LoadingStatus.NOT_LOADED,
    dltuserByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    geteditUserLoadingStatus: LoadingStatus.NOT_LOADED,
    getupdateUserByIdLoadingStatu: LoadingStatus.NOT_LOADED,
    userDetails: null,
    isLoggedin: false,
    loginError: null,
    forgotPasswordError: null,
    departmentError: null,
    departmentList: [],
    departmentListbyId: [],

    adddemartmentError: null,
    updatedemartmentError: null,
    getdemartmentError: null,
    deletedemartmentError: null,

    // user
    userError: null,
    userList: [''],
    adduserError: null,

    addUserList: [''],
    geteditUserError: null,
    geteditUserListbyID: [''],
    dltuserByIdList: [''],
    dltuserByIdError: null,
    getupdateUserByIdList: [''],
    getupdateUserByIdError: null
});

/**
 *  Stay Login Action
 */

export const stayLoginAction = createAsyncThunk('auth/stayLoginAction', async (params, thunkAPI) => {
    try {
        const userDetail = LocalStorage.getItem(LocalStorage.keys.USER_DETAILS);
        // console.log(userDetail);
        if (userDetail) {
            return JSON.parse(userDetail);
        } else {
            return thunkAPI.rejectWithValue('Logout');
        }
    } catch (error) {
        console.log('err', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Login action
 */
export const getDepartment = createAsyncThunk('auth/getDepartment', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/getdepartment/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        //   console.log('>>>>>>',response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const deleteDepartment = createAsyncThunk('auth/deleteDepartment', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/deletedepartment/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        //   console.log('>>>>>>',response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const updatedepartmentbyID = createAsyncThunk('auth/updatedepartmentbyID', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/update-department`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        console.log('>>>>>>', response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});
/**
 * Login action
 */
export const departmentAction = createAsyncThunk('auth/departmentAction', async (params, thunkAPI) => {
    // console.log("departmentAction called");
    try {
        const response = await request({
            url: `api/department`,
            method: 'GET',
            data: params,
            mode: 'cors'
        });
        //   console.log('>>>>>>',response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

// user api
export const userAction = createAsyncThunk('auth/userAction', async (params, thunkAPI) => {
    // console.log('>>>userAction>>>', userAction);

    try {
        const response = await request({
            url: `api/user`,
            method: 'GET',
            data: params,
            mode: 'cors'
        });
        //   console.log('>>>123>>>',response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const adduser = createAsyncThunk('auth/adduser', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/add-user`,
            method: 'POST',
            data: params,
            mode: 'cors'
        });
        // console.log('>>>12345>>>', response);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

// edit
export const geteditUser = createAsyncThunk('auth/geteditUser', async (params, thunkAPI) => {
    // console.log('geteditUser called');
    try {
        const response = await request({
            url: `api/get-user/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        // console.log('>>12345geteditUser>>>>', response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

// udate
export const getupdateUserById = createAsyncThunk('auth/getupdateUserById', async (params, thunkAPI) => {
    // console.log('add-udateuser called');
    try {
        const response = await request({
            url: `api/update-user`,
            method: 'POST',
            data: params,
            mode: 'cors'
        });
        // console.log('>>22222add-udateuser>>>>', response);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});
//delete

export const dltuserById = createAsyncThunk('auth/dltuserById', async (params, thunkAPI) => {
    // console.log('dltuserById called');
    try {
        const response = await request({
            url: `api/delete-user/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        // console.log('>>>dltuserById>>>', response);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});
export const addDepartment = createAsyncThunk('auth/addDepartment', async (params, thunkAPI) => {
    // console.log('departmentAction called');
    try {
        const response = await request({
            url: `api/adddepartment`,
            method: 'POST',
            data: params,
            mode: 'cors'
        });
        //   console.log('>>>>>>',response);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

/**
 * Login Action
 */

export const loginAction = createAsyncThunk('auth/loginAction', async (params, thunkAPI) => {
    // console.log('here we', params);
    try {
        const response = await request({
            url: `api/login`,
            method: 'POST',
            data: params,
            mode: 'cors'
        });
        const { isChecked } = params;
        if (response.code == '200') {
            LocalStorage.setItem(LocalStorage.keys.IS_REMEMBER, `${isChecked}`);
            const result = {
                name: response.data.name,
                email: response.data.email,
                type: response.data.user_details.type_id,
                token: response.token
            };
            return result;
        } else {
            return thunkAPI.rejectWithValue('No data found.');
        }
    } catch (error) {
        console.log('error', error.message);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * forgot password Action
 */

export const forgotPasswordAction = createAsyncThunk('auth/forgotPasswordAction', async (params, thunkAPI) => {
    try {
        // Api for login
        const reqBody = {
            email: params?.email,
            isAdmin: true
        };
        const result = await axiosRequest({
            url: ApiPath.FORGOT_PASS,
            method: 'POST',
            data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  1 Logout Action
 */

export const logoutAction = createAsyncThunk('auth/logout', async (params, thunkAPI) => {
    try {
        LocalStorage.clearAllItem();
        return true;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetauthState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // stay login action
            .addCase(stayLoginAction.fulfilled, (state, action) => {
                state.stayLoginLoadingStatus = LoadingStatus.LOADED;
                state.isLoggedin = true;
            })
            // logout action
            .addCase(logoutAction.fulfilled, (state, action) => {
                state.isLoggedin = false;
                ToastMessage.success('You have logged out successfully');
            })
            // login action
            .addCase(loginAction.pending, (state, action) => {
                state.loginError = null;
                state.loginLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.loginLoadingStatus = LoadingStatus.LOADED;
                state.isLoggedin = true;
                state.userDetail = action.payload;

                LocalStorage.setItem(LocalStorage.keys.USER_DETAILS, JSON.stringify(action.payload));
                ToastMessage.success('You have logged in successfully');
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.loginLoadingStatus = LoadingStatus.FAILED;
                state.loginError = action.payload || action.error.message;
                ToastMessage.error(state.loginError);
            })
            // forgot password action
            .addCase(forgotPasswordAction.pending, (state, action) => {
                state.forgotPasswordError = null;
                state.forgotPasswordLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(forgotPasswordAction.fulfilled, (state, action) => {
                state.forgotPasswordLoadingStatus = LoadingStatus.LOADED;
            })
            .addCase(forgotPasswordAction.rejected, (state, action) => {
                state.forgotPasswordLoadingStatus = LoadingStatus.FAILED;
                state.forgotPasswordError = action.payload || action.error.message;
            })

            // deartment action
            .addCase(departmentAction.pending, (state, action) => {
                state.demartmentError = null;
                state.demartmentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(departmentAction.fulfilled, (state, action) => {
                state.demartmentLoadingStatus = LoadingStatus.LOADED;
                state.departmentList = action.payload;
            })
            .addCase(departmentAction.rejected, (state, action) => {
                state.demartmentLoadingStatus = LoadingStatus.FAILED;
                state.demartmentError = action.payload || action.error.message;
            })
            // user action
            .addCase(userAction.pending, (state, action) => {
                state.userError = null;
                state.userLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(userAction.fulfilled, (state, action) => {
                state.userLoadingStatus = LoadingStatus.LOADED;
                state.userList = action.payload;
            })
            .addCase(userAction.rejected, (state, action) => {
                state.userLoadingStatus = LoadingStatus.FAILED;
                state.userError = action.payload || action.error.message;
            })
            //add user action
            .addCase(adduser.pending, (state, action) => {
                state.adduserError = null;
                state.userLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(adduser.fulfilled, (state, action) => {
                state.adduserLoadingStatus = LoadingStatus.LOADED;
                state.addUserList = action.payload;
            })
            .addCase(adduser.rejected, (state, action) => {
                state.adduserLoadingStatus = LoadingStatus.FAILED;
                state.adduserError = action.payload || action.error.message;
            })
            // get editser action
            .addCase(geteditUser.pending, (state, action) => {
                state.geteditUserError = null;
                state.geteditUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(geteditUser.fulfilled, (state, action) => {
                state.geteditUserLoadingStatus = LoadingStatus.LOADED;
                state.geteditUserListbyID = action.payload;
            })
            .addCase(geteditUser.rejected, (state, action) => {
                state.geteditUserLoadingStatus = LoadingStatus.FAILED;
                state.geteditUserError = action.payload || action.error.message;
            })
            // get getupdateUser action
            .addCase(getupdateUserById.pending, (state, action) => {
                state.getupdateUserByIdError = null;
                state.getupdateUserByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getupdateUserById.fulfilled, (state, action) => {
                state.getupdateUserByIdLoadingStatus = LoadingStatus.LOADED;
                state.getupdateUserByIdList = action.payload;
            })
            .addCase(getupdateUserById.rejected, (state, action) => {
                state.getupdateUserByIdLoadingStatus = LoadingStatus.FAILED;
                state.getupdateUserByIdError = action.payload || action.error.message;
            })
            // userDelete
            .addCase(dltuserById.pending, (state, action) => {
                state.dltuserByIdError = null;
                state.dltuserByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(dltuserById.fulfilled, (state, action) => {
                state.dltuserByIdLoadingStatus = LoadingStatus.LOADED;
                state.dltuserByIdList = action.payload;
            })
            .addCase(dltuserById.rejected, (state, action) => {
                state.dltuserByIdLoadingStatus = LoadingStatus.FAILED;
                state.dltuserByIdError = action.payload || action.error.message;
            })
            // Add Department action
            .addCase(addDepartment.pending, (state, action) => {
                state.adddemartmentError = null;
                state.adddemartmentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addDepartment.fulfilled, (state, action) => {
                state.adddemartmentLoadingStatus = LoadingStatus.LOADED;
                // state.departmentList = action.payload;
            })
            .addCase(addDepartment.rejected, (state, action) => {
                state.adddemartmentLoadingStatus = LoadingStatus.FAILED;
                state.adddemartmentError = action.payload || action.error.message;
            })

            // get Department action
            .addCase(getDepartment.pending, (state, action) => {
                state.getdemartmentError = null;
                state.getdemartmentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getDepartment.fulfilled, (state, action) => {
                state.getdemartmentLoadingStatus = LoadingStatus.LOADED;
                state.departmentListbyID = action.payload;
                // state.departmentList = action.payload;
            })
            .addCase(getDepartment.rejected, (state, action) => {
                state.getdemartmentLoadingStatus = LoadingStatus.FAILED;
                state.getdemartmentError = action.payload || action.error.message;
            })

            // get Department action
            .addCase(deleteDepartment.pending, (state, action) => {
                state.deletedemartmentError = null;
                state.deletedemartmentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteDepartment.fulfilled, (state, action) => {
                state.deletedemartmentLoadingStatus = LoadingStatus.LOADED;
                state.departmentListbyID = action.payload;
            })
            .addCase(deleteDepartment.rejected, (state, action) => {
                state.deletedemartmentLoadingStatus = LoadingStatus.FAILED;
                state.deletedemartmentError = action.payload || action.error.message;
            })

            // get Department action
            .addCase(updatedepartmentbyID.pending, (state, action) => {
                state.updatedemartmentError = null;
                state.updatedemartmentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updatedepartmentbyID.fulfilled, (state, action) => {
                state.updatedemartmentLoadingStatus = LoadingStatus.LOADED;
                state.departmentListbyID = action.payload;
            })
            .addCase(updatedepartmentbyID.rejected, (state, action) => {
                state.updatedemartmentLoadingStatus = LoadingStatus.FAILED;
                state.updatedemartmentError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
