import { toast } from 'react-toastify';
const ToastMessage = {
    success: (message = 'Success Notification !', position = toast.POSITION.TOP_RIGHT) =>
        toast.success(message, {
            position: position
        }),

    error: (message = 'Error Notification !', position = toast.POSITION.TOP_RIGHT) =>
        toast.error(message, {
            position: position
        }),

    warn: (message = 'Warning Notification !', position = toast.POSITION.TOP_RIGHT) =>
        toast.warn(message, {
            position: position
        }),

    info: (message = 'Info Notification !', position = toast.POSITION.TOP_RIGHT) =>
        toast.info(message, {
            position: position
        }),

    custom: (message = 'Custom Style Notification with css class!', position = toast.POSITION.TOP_RIGHT, className) =>
        toast(message, {
            position: position,
            className: className
        })
};
export default ToastMessage;
