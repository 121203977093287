import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../helper';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import LocalStorage from 'utils/localStorage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'task';

const initialState = entityAdapter.getInitialState({
    taskError: null,
    taskList: [],
    taskDetails: [],
    taskLoadingStatus: LoadingStatus.NOT_LOADED
});

export const taskListAction = createAsyncThunk('auth/taskListAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/task`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const addTaskAction = createAsyncThunk('auth/addTaskAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/add-task`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const getTaskByIdAction = createAsyncThunk('auth/getTaskByIdAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/get-task/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const updateTaskByIdAction = createAsyncThunk('auth/updateTaskByIdAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/update-task`,
            method: 'post',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

export const onDeleteTaskAction = createAsyncThunk('auth/onDeleteTaskAction', async (params, thunkAPI) => {
    try {
        const response = await request({
            url: `api/delete-task/${params}`,
            method: 'get',
            data: params,
            mode: 'cors'
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.message);
    }
});

const taskSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        taskState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // task List
            .addCase(taskListAction.fulfilled, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADED;
                state.taskList = action.payload.data;
            })
            .addCase(taskListAction.pending, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(taskListAction.rejected, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.FAILED;
                state.taskError = action.payload || action.error.message;
            })

            // task Add
            .addCase(addTaskAction.fulfilled, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADED;
            })
            .addCase(addTaskAction.pending, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addTaskAction.rejected, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.FAILED;
                state.taskError = action.payload || action.error.message;
            })

            // task get
            .addCase(getTaskByIdAction.fulfilled, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADED;

                state.taskDetails = action.payload;
            })
            .addCase(getTaskByIdAction.pending, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getTaskByIdAction.rejected, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.FAILED;
                state.taskError = action.payload || action.error.message;
            })

            // task Update
            .addCase(updateTaskByIdAction.fulfilled, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADED;
                state.taskDetails = action.payload;
            })
            .addCase(updateTaskByIdAction.pending, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateTaskByIdAction.rejected, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.FAILED;
                state.taskError = action.payload || action.error.message;
            })

            // task Delete
            .addCase(onDeleteTaskAction.fulfilled, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADED;
                state.taskDetails = action.payload;
            })
            .addCase(onDeleteTaskAction.pending, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(onDeleteTaskAction.rejected, (state, action) => {
                state.taskLoadingStatus = LoadingStatus.FAILED;
                state.taskError = action.payload || action.error.message;
            });
    }
});

export const taskReducer = taskSlice.reducer;

export const taskActions = taskSlice.actions;
