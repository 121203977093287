import axios from 'axios';
import LocalStorage from 'utils/localStorage';
// const service = axios.create()
const token = LocalStorage.getItem(LocalStorage.keys.USER_DETAILS);

const Token_value = token ? JSON.parse(token) : '';

const service = axios.create({
    // baseURL: 'http://localhost/userrolebackend/public/',
    // shreya
    baseURL: 'https://backend.rnss.net.in',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${Token_value.token}`
    }
});

service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response.status === 500) {
            console.log('500');
        } else if (error.response.status === 401) {
            console.log('401');
        } else if (error.response.status === 403) {
            console.log('403');
        } else if (error.response.status === 404) {
            console.log('404');
        }
        return Promise.reject(error);
    }
);

export default service;
