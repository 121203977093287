import { useRoutes } from 'react-router-dom';
import LocalStorage from 'utils/localStorage';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useEffect } from 'react';
import { stayLoginAction } from 'redux/reducers/auth.slice';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = (props) => {
    const { authReducer, stayLogin } = props;
    useEffect(() => {
        stayLogin();
    }, []);
    const userDetails = LocalStorage.getItem(LocalStorage.keys.USER_DETAILS);
    return useRoutes(userDetails ? MainRoutes(userDetails) : AuthenticationRoutes(userDetails));
};

ThemeRoutes.propTypes = {
    authReducer: PropTypes.object,
    stayLogin: PropTypes.func
};

const mapStateToProps = (state) => {
    return { authReducer: state.authReducer };
};

const mapDispatchToProps = (dispatch) => ({
    stayLogin: () => dispatch(stayLoginAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeRoutes);
