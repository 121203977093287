const isDev = true;
const ApiPath = {
    BASE_URL: isDev ? 'https://php8.singsys.net/yogamobapp/api' : '',
    LOGIN: '/login',
    FORGOT_PASS: '/forget-password-email'
};

const ValidationMessage = {};
const AlertMessage = {};

const LoadingStatus = {
    NOT_LOADED: 'NOT_LOADED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED'
};

export { ApiPath, ValidationMessage, LoadingStatus, AlertMessage };
