import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Privacy = Loadable(lazy(() => import('views/doc/PrivacyPolicy')));
const Term = Loadable(lazy(() => import('views/doc/TermCondiition')));
const About = Loadable(lazy(() => import('views/doc/About')));
const Generatepassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/AuthGeneratePasswaord')));
const Forgotpassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/AuthForgotPassword')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn) => [
    {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/',
                element: <AuthLogin3 />
            },
            {
                path: 'register',
                element: <AuthRegister3 />
            },
            {
                path: 'privacy-policy',
                element: <Privacy />
            },
            {
                path: 'terms-and-conditions',
                element: <Term />
            },
            {
                path: 'about-us',
                element: <About />
            },
            {
                path: 'generate-password',
                element: <Generatepassword />
            },
            {
                path: 'forgot-password',
                element: <Forgotpassword />
            }
        ]
    }
];

export default AuthenticationRoutes;
